export const footerLinks = [
  {
    title: 'Épanouissement',
    link: '/blog/epanouissement/',
    links: [
      {
        to: '/blog/epanouissement/troubles-sexuels-comment-en-parler/',
        title: 'Troubles sexuels'
      },
      {
        to: '/blog/epanouissement/comment-trouver-son-sa-sexologue/',
        title: 'Trouver sa sexologue'
      },
      {
        to: '/blog/epanouissement/comment-muscler-son-perinee/',
        title: 'Muscler son périnée'
      }
    ],
  },
  {
    title: 'Plaisir',
    link: '/blog/plaisir/',
    links: [
      {
        to: '/blog/plaisir/anorgasmie-definition-causes-solutions/',
        title: 'Anorgasmie',
      },
      {
        to: '/blog/plaisir/plaisir-feminin-absence-causes-solutions/',
        title: 'Absence de plaisir',
      },
      {
        to: '/blog/plaisir/le-guide-complet-de-lorgasme-feminin/',
        title: 'Orgasme : guide complet',
      },
    ],
  },
  {
    title: 'Libido',
    link: '/blog/libido/',
    links: [
      {
        to: '/blog/libido/comment-expliquer-une-baisse-de-libido/',
        title: 'Baisse de libido',
      },
      {
        to: '/blog/libido/menopause-comment-retrouver-sa-libido/',
        title: 'Ménopause et libido',
      },
      {
        to: '/blog/libido/comment-fonctionne-la-libido-chez-la-femme/',
        title: 'Comprendre la Libido',
      },
    ],
  },
  {
    title: 'Douleurs',
    link: '/blog/douleurs/',
    links: [
      {
        to: '/blog/douleurs/comment-soigner-la-dyspareunie/',
        title: 'Dyspareunie',
      },
      {
        to: '/blog/douleurs/vaginisme-causes-traitements-solutions/',
        title: 'Vaginisme',
      },
      {
        to: '/blog/douleurs/endometriose-symptomes-douleurs-traitements/',
        title: 'Endométriose',
      },
    ],
  },
  {
    title: 'Couple',
    link: '/blog/problemes-de-couple/',
    links: [
      {
        to: '/blog/problemes-de-couple/probleme-de-communication-dans-le-couple/',
        title: 'Communication dans le couple',
      },
      {
        to: '/blog/problemes-de-couple/therapie-de-couple-definition-prix/',
        title: 'Thérapie de couple',
      },
      {
        to: '/blog/problemes-de-couple/comment-expliquer-linfidelite/',
        title: 'Infidélité dans le couple',
      },
    ],
  },
];
