import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavbarBrand } from "react-bootstrap";

import Support from 'images/icons/start/support.svg';
import Logo from 'images/mia-logo-blue.svg';
import Phone from "images/icons/phone.svg";
import FrenchFlag from "images/icons/frenchFlag.svg";
import ArrowDown from "images/icons/Arrow-down.svg";

import PatientSpaceLink, {
  UnstyledPatientSpaceLink,
} from "components/common/PatientSpaceLink";

import { frontAppUrl } from 'utils/siteConfig';

import "./styles.sass"

const BlogHeader = ({
}) => {
  return (
    <>
      <header className="main-navbar main-navbar-blog">
        <div className="fixed-top">
          <Navbar
            collapseOnSelect
            expand="lg"
            className="charles-layout-blog"
          >
            <div className="top-nav-landing justify-content-lg-start">
              <div className="d-flex flex-row d-lg-none">
                <Navbar.Brand className="d-lg-none ml-0 mr-1 mx-md-2">
                  <Navbar.Toggle
                    className="navbar-toggler responsive-mobile-nav nav-toggler-landing"
                    aria-controls="responsive-navbar-nav"
                  />
                </Navbar.Brand>
                <NavbarBrand className="mx-1 mx-md-2">
                  <Link to="/" aria-label="retour à la page d'accueil">
                    <Logo className="d-lg-none logo-charles-nav" />
                  </Link>
                </NavbarBrand>
              </div>
              <Navbar.Brand className="mx-0 text-left ml-lg-3 ml-xl-0">
                <Link to="/" aria-label="retour à la page d'accueil">
                  <Logo className="d-none d-lg-block" height={36} />
                </Link>
                <Nav.Item className="phone-number-nav d-none d-lg-flex justify-content-center">
                  <Support height={17} className="phone-icon-landing" />
                  <a className="landing-number" href="tel:01-86-65-18-91">
                    01 86 65 18 91
                  </a>
                </Nav.Item>
              </Navbar.Brand>
              <div className="d-flex flex-row">
                <Navbar.Brand className="responsive-phone-call d-lg-none mx-1 mx-md-2">
                  <a
                    href="tel:01-86-65-17-33"
                    className="d-flex flex-row align-items-center h-100"
                  >
                    <Phone
                      height={22}
                      width={22}
                      className="phone-svg d-none d-lg-block"
                    />
                    <Support height={21} className="d-lg-none" />
                    <span className="flex-responsive">
                      <FrenchFlag width={17} />
                      Gratuit
                    </span>
                  </a>
                </Navbar.Brand>
              </div>
            </div>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-lg-end"
            >
              <Nav className="">
                <Nav.Item className="nav-item-padding">
                  <Link
                    to="/blog/libido/"
                    className="animation-header-landing special-hover"
                  >
                    Libido
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                </Nav.Item>
                <Nav.Item className="nav-item-padding">
                  <Link
                    to="/blog/douleurs/"
                    className="animation-header-landing special-hover"
                  >
                    Douleurs
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                </Nav.Item>
                <Nav.Item className="nav-item-padding">
                  <Link
                    to="/blog/plaisir/"
                    className="animation-header-landing special-hover"
                  >
                    Plaisir
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                </Nav.Item>
                <Nav.Item className="nav-item-padding">
                  <Link
                    to="/blog/problemes-de-couple/"
                    className="animation-header-landing special-hover"
                  >
                    Couple
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                </Nav.Item>
                <Nav.Item className="nav-item-padding">
                  <Link
                    to="/blog/epanouissement/"
                    className="animation-header-landing special-hover"
                  >
                    Épanouissement
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                </Nav.Item>
                <Nav.Item className="no-padding consulter-btn">
                  <Link
                    to="/consulter/"
                    activeClassName="nav-link-active"
                    className="no-underline-hover navbar-consult-cta"
                  >
                    <Button
                      variant="orange"
                      className="consult-button center-text-navbar"
                    >
                      <span>
                        Consulter
                      </span>
                    </Button>
                  </Link>
                </Nav.Item>
                <Nav.Item className="no-padding consulter-btn">
                  <a href={`${frontAppUrl}/me.html`} className="no-underline-hover navbar-consult-cta">
                    <Button
                      variant="purple"
                      className=""
                    >
                      <span>
                        Mon Espace
                      </span>
                    </Button>
                  </a>
                </Nav.Item>
              </Nav>
              <Nav className="responsive-bottom-nav-bar d-lg-none">
                <Nav.Item className="nav-item-padding">
                  <Link
                    to="/qui-sommes-nous/"
                    className=""
                    activeClassName="mobile-link-nav-active"
                  >
                    <Nav.Item className="d-flex justify-content-between align-items-center">
                      À propos
                      <ArrowDown className="mobile-arrow-nav" />
                    </Nav.Item>
                  </Link>
                </Nav.Item>
                <Nav.Item className="nav-item-padding">
                  <Link
                    to="/"
                    className=""
                    activeClassName="mobile-link-nav-active"
                  >
                    <Nav.Item className="d-flex justify-content-between align-items-center">
                      Accueil Mia.co
                      <ArrowDown className="mobile-arrow-nav" />
                    </Nav.Item>
                  </Link>
                </Nav.Item>
                <Nav.Item className="phone-number-nav justify-content-center">
                  <Support height={17} className="phone-icon-landing" />
                  <a className="landing-number" href="tel:01-86-65-18-91">
                    01 86 65 18 91
                  </a>
                </Nav.Item>
                <Nav.Item className="nav-item-padding">
                  <span className="disclaimer-emergency-header">
                    <span>
                      Mia.co n'est pas un service d'urgence.
                      <br />
                    </span>
                    <span className="font-weight-700">
                      {' '}
                      Pour toute
                      urgence,
                      appelez le 112
                      <br />
                    </span>
                    <span className="mobile-emergency"> (numéro d'urgence européen)</span>
                  </span>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    </>
  );
}

export default BlogHeader;
