// Styles
import './fonts.sass';
import './styles.sass';

import PatientSpaceLink, { UnstyledPatientSpaceLink } from 'components/common/PatientSpaceLink';

import ArrowDown from 'images/icons/Arrow-down.svg';
import BlogLinkFarm from "./BlogLinkFarm";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CookieConsent from './CookieConsent';
import CrossToggler from 'images/icons/cross-toggler.svg';
import DefaultHeader from "./DefaultHeader";
import Flower from 'images/mia-flower.svg';
import Helmet from 'react-helmet';
import Image from 'components/common/utils/Image';
import IntercomWrapper from 'components/common/utils/IntercomWrapper'
import { Link } from 'gatsby';
import Logo from 'images/mia-logo-blue.svg';
import LogoMarque from 'images/mia-logo-marque.svg';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavbarBrand } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Section from 'components/common/Section';
import Support from 'images/icons/start/support.svg';
import TextSectionList from '../TextSectionList';
import User from 'images/icons/user.svg';
import cx from 'classnames';
import { sections } from '../../Landing/data';

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

const MiaLayout = ({
  children,
  bodyClass = '',
  showBars = true,
  onToggle,
  showLinksInFooter = true,
  showStickyNavbar = true,
  showSpecialtiesInFooter = false,
  showOnlyFooter = false,
  footerCtaPath = '/consulter/',
  backgroundBeforeFooter = 'background-grey-white',
  showSextionBeforeFooter = true,
  showNewsletterBeforeFooter = false, // till it works
  isBlog = false
}) => {
  return (
    <>
      <Helmet>
        <body className={bodyClass} />
      </Helmet>
      <div className="viewport">
        <div className="viewport-top">
          {/* The main header section on top of the screen */}
          {!showOnlyFooter && showBars &&
            <DefaultHeader showStickyNavbar={showStickyNavbar} isBlog={isBlog} />
          }

          <main
            className={cx('charles-main', {
              'with-navbar': showBars,
              'without-sticky-navbar': !showStickyNavbar,
              'grey-background': onToggle,
              'charles-main-blog': isBlog,
            })}
          >
            {/* All the main content gets inserted here, index.js, post.js */}
            {children}
          </main>
        </div>

        <div className="viewport-bottom">
          {(showNewsletterBeforeFooter) && (
            <Section className="d-flex flex-column align-items-center">
              <div className="newsletter-div">
                <Flower className="newsletter-top-left-flower" height={56} width={56} />
                <div className="newsletter-backgroung-color-div">
                  <div className="newsletter-text-div">
                    <h3 className="newsletter-title">
                      Conseils sexo, témoignages,
                      <br />
                      nouveautés : suivez le mouvement&nbsp;!
                    </h3>
                    <div className="d-flex flex-column newsletter-input-div">
                      <input type="text" className="form-control newsletter-text-input" placeholder="Adresse email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                      <Button className="newsletter-submit-button" type="button" variant="orange">
                        <span>
                          M'ABONNER
                          {' '}
                          <span className="d-none d-lg-inline">
                            À LA NEWSLETTER
                          </span>
                        </span>
                      </Button>
                    </div>
                    <span className="newsletter-disclaimer">
                      Votre adresse email est uniquement utilisée pour vous envoyer notre
                      newsletter. Désinscription à tout moment à l'aide du lien inclus dans
                      chaque newsletter.
                    </span>
                  </div>
                </div>
                <div className="newsletter-img-div d-none d-lg-inline">
                  <Image filename="newsletter-img.png" className="newsletter-img d-none d-lg-block" />
                </div>
                <Flower className="newsletter-bottom-right-flower" height={37} width={37} />
              </div>
            </Section>
          )}
          { showBars && !isBlog && <BlogLinkFarm /> }
          {/* The footer at the very bottom of the screen */}
          {(showOnlyFooter || showBars) && (
          <footer className="mia-footer">
            <Section className="py-lg-4 footer-section">
              <Container fluid>
                <Row className="footer-top-col d-lg-none">
                  <Col xs={12} className="d-flex flex-column align-items-center">
                    <Logo width={90} />
                    <div className="d-flex footer-phone-block">
                      <Support height={20} className="" />
                      <a className="landing-number footer-number" href="tel:01-86-65-18-91">01 86 65 18 91</a>
                    </div>
                  </Col>
                  <Col xs={12} className="social-links">
                    <div className="follow-us">Suivez-nous sur :</div>
                    <div>
                      <a
                        href="https://www.facebook.com/mia.cofrance/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          className="img-fluid social-icon social-icon-regular"
                          filename="icons/social/facebook.svg"
                          alt="Mia.co sur Facebook"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/mia.co_fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          className="img-fluid social-icon social-icon-regular"
                          filename="icons/social/instagram.svg"
                          alt="Mia.co sur Instagram"
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCXVQTxdb9G5holCE340LblQ"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          className="img-fluid social-icon social-icon-regular"
                          filename="icons/social/youtube.svg"
                          alt="Mia.co sur Youtube"
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row className="footer-links-second-section">
                  <Col lg={3} className="d-none d-lg-inline">
                    <div className="footer-logo-container">
                      <Logo width={90} />
                    </div>
                    <div className="d-flex footer-phone-block">
                      <Support className="phone-icon-landing" />
                      <a className="landing-number footer-number" href="tel:01-86-65-18-91">01 86 65 18 91</a>
                    </div>
                    <div className="social-links">
                      <div className="follow-us d-lg-none">Suivez-nous sur :</div>
                      <div className="d-flex justify-content-between">
                        <a
                          href="https://www.facebook.com/mia.cofrance/"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <Image
                            className="img-fluid social-icon social-icon-regular"
                            filename="icons/social/facebook.svg"
                            alt="Mia.co sur Facebook"
                          />
                          <Image
                            className="img-fluid social-icon social-icon-hover"
                            filename="icons/social/facebook-hover.svg"
                            alt="Mia.co sur Facebook"
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/mia.co_fr/"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <Image
                            className="img-fluid social-icon social-icon-regular"
                            filename="icons/social/instagram.svg"
                            alt="Mia.co sur Instagram"
                          />
                          <Image
                            className="img-fluid social-icon social-icon-hover"
                            filename="icons/social/insta-hover.svg"
                            alt="Mia.co sur Instagram"
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCXVQTxdb9G5holCE340LblQ"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <Image
                            className="img-fluid social-icon social-icon-regular"
                            filename="icons/social/youtube.svg"
                            alt="Mia.co sur Youtube"
                          />
                          <Image
                            className="img-fluid social-icon social-icon-hover"
                            filename="icons/social/youtube-hover.svg"
                            alt="Mia.co sur Youtube"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="align-items-center">
                      <div className="safe-data">
                        <span className="weighter-span">Vos données sont sécurisées </span>
                        et soumises au secret médical.
                      </div>
                      <div className="safe-data">
                        Mia.co n’est pas un service d’urgence.
                        {' '}
                        <span className="weighter-span">Pour toute urgence, appelez le 112 </span>
                        (numéro d’urgence européen).
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} lg className="footer-links-margin-top">
                    <h4 className="font-size-title-footer mb-2">Support</h4>
                    <ul className="list-unstyled font-list-footer">
                      <li>
                        <Link to="/">Accueil</Link>
                      </li>
                      <li>
                        <Link to="/faq/">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/comment-ca-marche/">
                          Comment ça marche ?
                        </Link>
                      </li>
                      <li>
                        <UnstyledPatientSpaceLink title="Accès patient" />
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} lg className="footer-links-margin-top">
                    <h4 className="font-size-title-footer mb-2">À propos</h4>
                    <ul className="list-unstyled font-list-footer">
                      <li>
                        <Link to="/qui-sommes-nous/">Sexologues</Link>
                      </li>
                      <li>
                        <Link to="/blog/">Blog</Link>
                      </li>
                      <li>
                        <Link to="/conditions-generales/">CGUV</Link>
                      </li>
                      <li>
                        <Link to="/politique-de-confidentialite/">
                          Politique de confidentialité
                        </Link>
                      </li>
                      <li>
                        <Link to="/presse/">
                          Presse
                        </Link>
                      </li>
                      <li>
                        <Link to="/livres-blancs/">
                          Nos guides
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} lg className="footer-links-margin-top d-none d-md-block d-lg-none">
                    <h4 className="font-size-title-footer mb-2">Symptômes</h4>
                    <ul className="list-unstyled font-list-footer">
                      <li>
                        <Link to="/baisse-de-libido/">
                          Baisse de libido
                        </Link>
                      </li>
                      <li>
                        <Link to="/douleur/">
                          Douleurs
                        </Link>
                      </li>
                      <li>
                        <Link to="/plaisir/">
                          Plaisir
                        </Link>
                      </li>
                      <li>
                        <Link to="/couple/">
                          Couple
                        </Link>
                      </li>
                      <li>
                        <Link to="/epanouissement/">
                          Épanouissement
                        </Link>
                      </li>
                      <li>
                        <Link to="/problemes-sexuels/">
                          Autres
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} lg className="footer-links-margin-top d-md-none d-lg-block">
                    <h4 className="font-size-title-footer mb-2">Symptômes</h4>
                    <ul className="list-unstyled font-list-footer">
                      <li>
                        <Link to="/baisse-de-libido/">
                          Baisse de libido
                        </Link>
                      </li>
                      <li>
                        <Link to="/douleur/">
                          Douleurs
                        </Link>
                      </li>
                      <li>
                        <Link to="/plaisir/">
                          Plaisir
                        </Link>
                      </li>
                      <li>
                        <Link to="/couple/">
                          Couple
                        </Link>
                      </li>
                      <li>
                        <Link to="/epanouissement/">
                          Épanouissement
                        </Link>
                      </li>
                      <li>
                        <Link to="/problemes-sexuels/">
                          Autres
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} className="d-lg-none">
                    <div className="d-flex flex-column align-items-center">
                      <div className="safe-data">
                        <span className="weighter-span">Vos données sont sécurisées </span>
                        et soumises au secret médical.
                      </div>
                      <div className="safe-data">
                        Mia.co n’est pas un service d’urgence.
                        {' '}
                        <span className="weighter-span">Pour toute urgence, appelez le 112 </span>
                        (numéro d’urgence européen).
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={2} className="mt-3">
                    <div className="d-flex flex-row flex-lg-column justify-content-around img-div-footer align-items-lg-end">
                      <Image
                        className="img-hads-footer"
                        filename="icons/HADS.svg"
                        alt="Mia.co | Hébergeur Agréé de Données de Santé à caractère personnel"
                      />
                      <Image
                        className="img-mangopay-footer"
                        filename="icons/mangopay-blue.svg"
                        alt="Paiements sécurisées via Mangopay"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Section>
          </footer>
          )}
        </div>
        <IntercomWrapper />
        <CookieConsent />
      </div>
    </>
  );
};

MiaLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  showBars: PropTypes.bool,
  showOnlyFooter: PropTypes.bool,
  backgroundBeforeFooter: PropTypes.string,
};

export default MiaLayout;
