const canonicalSiteUrl = "https://mia.co";
const siteUrl =
  process.env.NODE_ENV === `development`
    ? "http://localhost:8000"
    : process.env.SCALINGO_BUILD === `true`
    ? `https://${process.env.APP}.${process.env.REGION}.scalingo.io`
    : canonicalSiteUrl;

const frontAppUrl = process.env.GATSBY_FRONT_APP_URL;

module.exports = {
  canonicalSiteUrl,
  frontAppUrl,
  siteUrl, // Site domain. Do not include a trailing slash!
  siteName: 'Mia.co',

  logo: '/images/mia-social.png',
  blogPrefix: '/blog',

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires
  // sometimes to delete the cache)

  siteTitleMeta: 'Mia.co', // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: 'Conseils et diagnostic en ligne. Consultation avec des sexologues et thérapeutes de couple. Échanges confidentiels avec un spécialiste. Service sûr & discret. Service Patient 5j/7.',

  shareImage: '/images/mia-social.png', // fallback image for meta data. Will be used, when no post/tag/author image specified. 1200x1200 is recommended
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 1200, // Change to the height of your default share image

  shortTitle: 'Mia.co', // Used for App manifest e.g. Mobile Home Screen
  siteIcon: '/favicon.png', // Trying to move this to not static ---ORIGINAL COMMENT : Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: '#71E0DC', // Used for Offline Manifest
  themeColor: '#002865', // Used for Offline Manifest

  twitter: '@charlescofr',
  facebook: 'mia.cofrance',
  facebookAppId: '891268654262273',

  legalName: 'Charles SAS',
  youtubeChannelUrl: 'https://www.youtube.com/channel/UCXVQTxdb9G5holCE340LblQ',
  linkedInUrl: 'https://www.linkedin.com/company/charleshealth',
  infogreffeUrl: 'https://www.infogreffe.com/entreprise-societe/844332130-charles-750118B29550.html',

  fullAddress: '112 avenue de Paris, 94306 Vincennes Cedex',
  legalAddress: '112 avenue de Paris',
  legalPostcode: '94306',
  legalCity: 'Vincennes',
  phoneNumber: '+33186651733',
};
