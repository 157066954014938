import React from 'react';
import { Link } from 'gatsby';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import cx from "classnames";
import Section from "components/common/Section";

import { footerLinks } from "./data";

import './styles.sass';

class BlogLinkFarm extends React.Component {
  render() {
    return (
      <Section className="blog-link-farm-block background-color-pale-yellow footer-links-first-section d-flex">
        <div className="d-flex flex-column align-items-center mx-auto">
          <h3 className="section-title text-center text-lg-left h2-30px-font-size h2-title mb-2">
            Comprendre sur le blog
          </h3>
          <div className="d-none d-lg-block">
            <div className="mt-5 mb-5 d-flex flex-row justify-content-around">
              {footerLinks.map((category, index) => (
                <div
                  className="d-flex flex-column blog-column"
                  key={index}
                >
                  <a
                    href={category.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blog-link-farm-block-link"
                  >
                    <h4 className="blog-link-farm-block-title mb-2">
                      {category.title}
                    </h4>
                  </a>
                  <ul className="list-unstyled">
                    {category.links.map((link, linkIndex) => (
                      <li key={`footer_${index}_link_${linkIndex}`}>
                        {' '}
                        {link.to ? (
                          <a
                            href={link.to}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="category-info blog-link-farm-block-link"
                          >
                            {link.title}
                          </a>
                        ) : (
                          <span>{link.title}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="d-lg-none w-100 px-4">
            <Accordion
              className={cx('landing-text-accordion text-left my-4')}
              allowMultipleExpanded
              allowZeroExpanded
              preExpanded={[]}
            >
              {footerLinks.map((el, index) => (
                <AccordionItem uuid={index} key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="d-flex flex-row align-items-center">
                        <div className="accordion-chevron mr-2" />
                        <a
                          className="blog-link-farm-block-link ml-1 accordion-button-label text-left"
                          href={el.link}
                        >
                          <h4>{el.title}</h4>
                        </a>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {" "}
                    <ul className="list-unstyled">
                      {el.links.map((link, linkIndex) => (
                        <li key={`footer_${index}_link_${linkIndex}`}>
                          -{" "}
                          {link.to ? (
                            <a
                              href={link.to}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="blog-link-farm-block-link"
                            >
                              {link.title}
                            </a>
                          ) : (
                            <span>{link.title}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </Section>
    )
  }
}

export default BlogLinkFarm;
