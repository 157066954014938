import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import config from 'utils/siteConfig';

const ImageMeta = ({ image, twitterImage, ogImage }) => {
  const specificTwitterImage = ogImage || image || twitterImage;
  const specificOpenGraphImage = twitterImage || image || ogImage;

  const actualTwitterImage = specificTwitterImage || config.shareImage;
  const actualOpenGraphImage = specificOpenGraphImage || config.shareImage;

  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={actualTwitterImage} />
      <meta property="og:image" content={actualOpenGraphImage} />
      {!specificOpenGraphImage && (
      <>
        <meta
          property="og:image:width"
          content={config.shareImageWidth}
        />
        <meta
          property="og:image:height"
          content={config.shareImageHeight}
        />
      </>
      )}
    </Helmet>
  );
};

ImageMeta.propTypes = {
  image: PropTypes.string,
  twitterImage: PropTypes.string,
  ogImage: PropTypes.string,
};

export default ImageMeta;
