import React, { Fragment } from 'react'
import Intercom from 'react-intercom'

class IntercomWrapper extends React.Component {

  render() {

    const intercomId = process.env.GATSBY_INTERCOM_ID
    if (typeof intercomId === 'undefined') {
      return <Fragment />
    }

    const intercom = {
      open: false,
      appID: intercomId,
      targetPlatform: 'mia',
      targetStack: 'landing',
      patientWorkflow: 'landing',
      onOpen: () => {},
      onClose: () => {},
      onUnreadCountChange: (unreadCount) => {},
      onInitialization: (intercom) => {},
    }

    return <Intercom {...intercom} />
  }
}

export default IntercomWrapper
