import React from 'react';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

import './styles.sass';

class CharlesCookieConsent extends React.Component {
  render() {
    return (
      <CookieConsent
        buttonText="Accepter les cookies"
        disableStyles
        containerClasses="cookieConsent-container d-flex align-items-center"
        contentClasses="cookieConsent-content"
        buttonClasses="cookieConsent-button btn btn-orange"
      >
        <div className="text-div">
          <span className="d-none d-md-inline">
            Mia utilise des cookies pour vous offrir une
            expérience utilisateur de qualité. En poursuivant
            votre navigation sur ce site, vous acceptez
            l’utilisation de cookies dans les conditions prévues par
            {' '}
            <Link to="politique-de-confidentialite/">
              notre politique de confidentialité. (En savoir plus)
            </Link>
          </span>
          <span className="d-md-none">
            En poursuivant votre navigation sur ce site, vous acceptez
            l’utilisation de cookies dans les conditions prévues par
            {' '}
            <Link to="politique-de-confidentialite/">
              la politique de confidentialité.
            </Link>
          </span>
        </div>
      </CookieConsent>
    );
  }
}

export default CharlesCookieConsent;
