import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: {
            extension: {
              regex: "/(jpg)|(jpeg)|(png)|(svg)/"
            }
          }) {
          nodes {
            relativePath
            name
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1200, placeholder: TRACED_SVG)
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.nodes.find((n) => n.relativePath.includes(props.filename));
      if (!image) {
        return null;
      }
      if (!image.childImageSharp && image.extension === 'svg') {
        return <img src={image.publicURL} alt={props.alt} {...props} />;
      }
      return (
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          className="img-fluid"
          {...props}
        />
      );
    }}
  />
);

Image.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Image;
