import PatientSpaceLink, { UnstyledPatientSpaceLink } from 'components/common/PatientSpaceLink';
import React, { Component } from 'react';

import ArrowDown from 'images/icons/Arrow-down.svg';
// import BlueSeparator from 'images/icons/blue-separator.svg';
// import ScrollHidingNavbar from "../ScrollHidingNavbar";
import BlogHeader from "../BlogHeader";
import Button from "react-bootstrap/Button";
import CrossToggler from 'images/icons/cross-toggler.svg';
import { Link } from 'gatsby';
import Logo from 'images/mia-logo-blue.svg';
import LogoMarque from 'images/mia-logo-marque.svg';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavbarBrand } from "react-bootstrap";
import PromotionBanner from 'components/LandingProgram/PromotionBanner';
import SubNavbar from '../SubNavbar';
import Support from 'images/icons/start/support.svg';
import User from 'images/icons/user.svg';
import cx from 'classnames';
import { frontAppUrl } from 'utils/siteConfig';

// import LogoBlue from "images/charles-co.svg";
// import LogoWhite from "images/logo-white.svg";
// import WhitePhone from "images/icons/white-ringing-phone.svg";
// import Phone from "images/icons/phone.svg";
// import BluePhone from "images/icons/blue-ringing-phone.svg";
// import FrenchFlag from "images/icons/frenchFlag.svg";
// import ArrowDown from "images/icons/Arrow-down.svg";




const DefaultHeader = ({
  showStickyNavbar = true,
  isBlog = false
}) => {
  function openNav() {
    document.getElementById('sidebar-mia').style.right = '0';
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  function closeNav() {
    document.getElementById('sidebar-mia').style.right = '100vw';
    document.getElementsByTagName('body')[0].style.overflow = 'initial';
  }

  if (isBlog)
    return <BlogHeader />;

  return (
    <>
      <header className="main-navbar">
      
        <div className="fixed-top">
        <PromotionBanner />
          <Navbar collapseOnSelect expand="lg" className="mia-layout">
            <div className="top-nav-landing justify-content-lg-between">
              <div className="d-flex flex-row d-lg-none justify-content-between w-100">
                <div className="d-flex">
                  <Button className="responsive-mobile-nav nav-toggler-landing" onClick={openNav} />
                  <NavbarBrand className="mx-2 mx-md-3">
                    <Link to="/">
                      <Logo className="logo-charles-nav" />
                    </Link>
                  </NavbarBrand>
                </div>
                <div className="d-flex align-items-center">
                  <a
                    className="d-flex"
                    href="tel:01-86-65-18-91"
                  >
                    <Support height={23} className="phone-icon-landing" />
                  </a>
                  <LogoMarque height={35} />
                </div>
              </div>
              <div className="d-none d-lg-flex flex-row align-items-center">
                <Navbar.Brand className="responsive-phone-call d-lg-none">
                  <a href="tel:01-86-65-18-91" className="d-flex flex-row align-items-center">
                    <Support height={25} width={25} className="d-lg-none" />
                  </a>
                </Navbar.Brand>
                <div className="mx-0 text-left ml-lg-3 ml-xl-0 d-flex flex-column justify-content-between">
                  <Link to="/">
                    <Logo className="d-none d-lg-block logo-charles-nav" />
                  </Link>
                  <Nav.Item className="charles-layout-font-color d-none d-lg-flex justify-content-center align-items-center phone-nav-item">
                    <Support height={20} className="phone-icon-landing" />
                    <a className="landing-number" href="tel:01-86-65-18-91">01 86 65 18 91</a>
                  </Nav.Item>
                </div>
              </div>
              <div className="d-none d-lg-flex align-items-center justify-content-between navbar-right-part">
                <Link className="no-underline-hover" to="/comment-ca-marche/" activeClassName="active-page">COMMENT ÇA MARCHE&nbsp;?</Link>
                <Link className="no-underline-hover" to="/qui-sommes-nous/" activeClassName="active-page">SEXOLOGUES</Link>
                <Link className="no-underline-hover" to="/faq/" activeClassName="active-page">FAQ</Link>
                {/* eslint-disable-next-line max-len */}
                <Link className="no-underline-hover" to="/blog/" activeClassName="active-page">BLOG</Link>
                <Link
                  to="/consulter/"
                  activeClassName="nav-link-active"
                  className="no-underline-hover navbar-consult-cta"
                >
                  <Button
                    variant="orange"
                    className="consult-button center-text-navbar"
                  >
                    <span>
                      CONSULTER
                    </span>
                  </Button>
                </Link>
                <PatientSpaceLink title="MON ESPACE" />
                <Navbar.Brand className="d-flex align-items-center mr-xl-0">
                  <LogoMarque width={38} height={34} />
                </Navbar.Brand>
              </div> 
            </div>
            <div id="sidebar-mia" className="collapse-sidebar">
              <div className="collapsed-content">
                <Nav className="d-lg-none mt-lg-4 collapsed-first-nav">
                  <a href={`${frontAppUrl}/me.html`} className="d-flex align-items-center collapsed-member">
                    <button type="submit" value="Submit" className="d-flex collapsed-member my-space-collapsed" onClick={closeNav}>
                      <User />
                      <span className=" ml-2">MON ESPACE</span>
                    </button>
                  </a>
                  <Link to="/baisse-de-libido/" className="d-flex justify-content-between align-items-center mobile-link-nav" activeClassName="nav-link-active" onClick={closeNav}>
                    <span className="mobile-link-nav-span " >BAISSE DE LIBIDO</span>
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                  <Link to="/douleur/" className="d-flex justify-content-between align-items-center mobile-link-nav" activeClassName="nav-link-active" onClick={closeNav}>
                    <span className="mobile-link-nav-span ">DOULEURS</span>
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                  <Link to="/plaisir/" className="d-flex justify-content-between align-items-center mobile-link-nav" activeClassName="nav-link-active" onClick={closeNav}>
                    <span className="mobile-link-nav-span ">PLAISIR</span>
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                  <Link to="/couple/" className="d-flex justify-content-between align-items-center mobile-link-nav" activeClassName="nav-link-active" onClick={closeNav}>
                    <span className="mobile-link-nav-span ">COUPLE</span>
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                  <Link to="/epanouissement/" className="d-flex justify-content-between align-items-center mobile-link-nav" activeClassName="nav-link-active" onClick={closeNav}>
                    <span className="mobile-link-nav-span ">ÉPANOUISSEMENT</span>
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                  <Link to="/problemes-sexuels/" className="d-flex justify-content-between align-items-center mobile-link-nav" activeClassName="nav-link-active" onClick={closeNav}>
                    <span className="mobile-link-nav-span ">AUTRES</span>
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                  <Nav.Item>
                  <div className="mobile-orange-bar-nav" />
                </Nav.Item>
                  <Link to="/vaginisme/" className="d-flex justify-content-between align-items-center mobile-link-nav" activeClassName="nav-link-active" onClick={closeNav}>
                    <span className="mobile-link-nav-span ">Programme VAGINISME</span>
                    <ArrowDown className="mobile-arrow-nav" />
                  </Link>
                </Nav>
                <Nav className="charles-layout-font-color responsive-bottom-nav-bar">
                  <Nav.Item className="nav-item-padding">
                    <Link
                      to="/comment-ca-marche/"
                      activeClassName="nav-link-active"
                      className="animation-header-landing special-hover div-link-header"
                      onClick={closeNav}
                    >
                      Comment ça marche&nbsp;?
                      <ArrowDown className="mobile-arrow-nav" />
                    </Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item-padding">
                    <Link
                      to="/qui-sommes-nous/"
                      activeClassName="nav-link-active"
                      className="animation-header-landing special-hover div-link-header"
                      onClick={closeNav}
                    >
                      Sexologues
                      <ArrowDown className="mobile-arrow-nav" />
                    </Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item-padding">
                    <Link
                      to="/faq/"
                      activeClassName="nav-link-active"
                      className="animation-header-landing special-hover div-link-header"
                      onClick={closeNav}
                    >
                      FAQ
                      <ArrowDown className="mobile-arrow-nav" />
                    </Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item-padding">
                    <Link
                      to="/blog/"
                      activeClassName="nav-link-active"
                      className="animation-header-landing special-hover div-link-header"
                      onClick={closeNav}
                    >
                      Blog
                      <ArrowDown className="mobile-arrow-nav" />
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Support height={17} className="phone-icon-landing" />
                    <a className="landing-number" href="tel:01-86-65-18-91">01 86 65 18 91</a>
                  </Nav.Item>
                  <Nav.Item className="nav-item-padding d-block d-lg-none">
                    <span className="disclaimer-emergency-header">
                      <span>
                        Mia.co n'est pas un service d'urgence.
                        <br />
                      </span>
                      <span className="font-weight-700">
                        {' '}
                        Pour toute
                        urgence,
                        appelez le 112
                        <br />
                      </span>
                      <span className="mobile-emergency"> (numéro d'urgence européen)</span>
                    </span>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="collapsed-secondary-div">
                <CrossToggler className="cross-toggler" onClick={closeNav} />
              </div>
            </div>

          </Navbar>
          <div className="d-none d-lg-flex navbar-submenu-div">
            {showStickyNavbar && (
            <SubNavbar />
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default DefaultHeader;
