import React from 'react';
import './styles.sass';
import { frontAppUrl } from 'utils/siteConfig';

export default ({ title }) => (
  <a href={`${frontAppUrl}/me.html`} className="profil-button-nav-landing d-flex justify-content-center">
    <button type="submit" value="Submit" className="btn btn-purple d-flex justify-content-around no-padding">
      <span className="my-profil-landing-nav">{title}</span>
    </button>
  </a>
);

export const UnstyledPatientSpaceLink = ({ title }) => (
  <a href={`${frontAppUrl}/me.html`}>
    <label htmlFor="submit" className="patient-space-label">{title}</label>
  </a>
);
