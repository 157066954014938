import React from 'react';
import Man1 from 'images/men/Marc.svg';
import Man2 from 'images/men/Fabien.svg';
import Man3 from 'images/men/Richard.svg';
import { Link } from 'gatsby';

export const testimonies = [
  {
    testimony: 'J’ai toujours été un peu timide lorsqu’on commence à parler d’intimité. C’est vrai qu’en passant via un site comme celui-ci, on se sent à l’aise et en confiance. La sexologue qui m’a suivi m’a permis de comprendre ce qui clochait, nous avons trouvé une solution qui je pense, est durable. Une libération.',
    patient: 'Charlotte',
    city: '',
    age: '25 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Je n’aurais pas pensé un jour consulter une sexologue et pourtant, quel bien fou ! Je remercie cette plateforme parce qu’on peut parler de ses problèmes intimes comme on parlerait de problèmes de dents ou de dos. C’est simple, transparent, facile et surtout sans jugement.',
    patient: 'Marie',
    city: '',
    age: '34 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: false,
    target: true,
  },
  {
    testimony:
      'Cela fait plusieurs années que j’avais fais une croix sur ma vie sexuelle et j’avais un peu de mal à aborder le sujet avec ma gynéco pour être franche... La sexologue que j’ai pu consulter via mia.co m’a permis de me reconnecter à mon corps, à mon plaisir et je suis en bonne voie pour retrouver une sexualité partagée…',
    patient: 'Sabine',
    city: '',
    age: '54 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: true,
    target: false,
  },
  {
    testimony: (
      <span>
        Pour moi, la meilleure consultation de sexologie c’était nos discussions hyper ouvertes
        entre copines. C’était le seul endroit où je me sentais à l’aise pour partager mes
        problèmes intimes et sexuels, c’est d’ailleurs via elles que j’ai entendu parler du site.
        Parfois, il faut un œil extérieur et un avis professionnel pour (vraiment) résoudre un
        problème en profondeur&nbsp;!
      </span>
    ),
    patient: 'Ariette',
    city: '',
    age: '37 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: false,
    target: true,
  },
];

export const sections = [
  {
    title:
      'Comment téléconsulter une sexologue sur Mia ?',
    body: (
      <div>
        <p>
          Pour téléconsulter une sexologue sur Mia.co vous devez :
        </p>
        <div className="ml-4">
          <p>
            • Remplir le questionnaire en ligne disponible
            {' '}
            <Link className="here-link" to="/consulter/">ici</Link>
            .
          </p>
          <p>
            • Planifier votre première téléconsultation selon 3 modes : vidéo, appel, messages écrits.
          </p>
          <p>
            • Confirmer votre RDV en renseignant vos informations personnelles et en pré-réglant la
            téléconsultation sur la plateforme.
          </p>
          <p>
            • Une fois la consultation terminée, RDV dans votre espace personnel Mia.co pour
            consulter votre dossier patient, le compte-rendu de la consultation et les
            recommandations d’accompagnement.
          </p>
        </div>
      </div>
    ),
  },
  {
    title:
      'Combien coûte la consultation avec une sexologue sur Mia.co ?',
    body: (
      <div>
        Les sexologues présentes sur la plateforme Mia.co proposent des consultations de 30 minutes à
        45 euros.
      </div>
    ),
  },
  {
    title:
      'Tout le monde peut-il bénéficier d’une téléconsultation sur Mia.co ?',
    body: (
      <div>
        Pour bénéficier d’une téléconsultation sur Mia.co, vous devez être une femme et/ou une
        personne à vulve.
      </div>
    ),
  },
  {
    title:
      'Qui sont les sexologues sur Mia.co ?',
    body: (
      <div>
        <p>
          Toutes les praticiennes de la plateforme Mia.co sont diplômées en sexologie. D’horizons divers
          (psychologues, sage-femmes, thérapeutes de couples), elles se retrouvent autour
          d’une conception commune de la sexologie et ont toutes été spécialement formées à la pratique
          de la téléconsultation.
        </p>
        <p>
          Elles sont encadrées par un comité scientifique composé de médecins sexologues et de
          professeurs d’université en sexologie et de membres dirigeants d’association de
          sexologues.
        </p>
      </div>
    ),
  },
  {
    title:
      'Est-il nécessaire d’effectuer la téléconsultation sous forme de visioconférence ?',
    body: (
      <div>
        Non, vous pouvez consulter de 3 façons différentes à votre convenance : par messagerie sécurisée, visioconférence ou appel téléphonique.
      </div>
    ),
  },
  {
    title:
      'La consultation est-elle remboursée par la sécurité sociale ou les mutuelles ?',
    body: (
      <div>
        La téléconsultation de sexologie n’est pas remboursée par la sécurité sociale. Cependant,
        certaines mutuelles peuvent prendre en charge une partie ou la totalité de votre
        consultation, selon votre contrat. Nos sexologues seront en mesure de vous fournir une
        facture sur demande à adresser à votre complémentaire de santé.
      </div>
    ),
  },
];
