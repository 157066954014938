import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel, AccordionItemState,
} from 'react-accessible-accordion';
import PlusIcon from 'images/icons/plus-icon.svg';
import MinusIcon from 'images/icons/minus-icon.svg';

import './styles.sass';

const TextSectionList = (props) => {
  const { sections, openFirst, className } = props;
  return (
    <Accordion
      className={cx('landing-text-accordion text-left', className)}
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={openFirst ? [0] : []}
    >
      {sections.map((el, index) => (
        <AccordionItem uuid={index} key={el.id} className="accordion-item">
          <AccordionItemHeading className="accordion-heading">
            <AccordionItemButton>
              <div className="d-flex flex-row align-items-center justify-content-start">
                <AccordionItemState>
                  {({ expanded }) => (expanded === true
                    ? <div className="accordion-item-div"><MinusIcon width={18} height={18} className="accordion-button-icon" /></div>
                    : <div className="accordion-item-div"><PlusIcon width={18} height={18} className="accordion-button-icon" /></div>)}
                </AccordionItemState>
                <div className="accordion-button-label text-left">
                  {el.title}
                </div>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">{el.body}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

TextSectionList.propTypes = {
  openFirst: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
    }),
  ).isRequired,
};
TextSectionList.defaultProps = {
  openFirst: true,
};

export default TextSectionList;
