import './styles.sass';

import Navbar from 'react-bootstrap/Navbar';
import React from 'react';

const IndexPage = () => (
  <Navbar className="promotion-nav d-none d-lg-flex justify-content-center bg-$lc-dark-blue">
    <span>-50 €&nbsp;sur le programme <span className="caps">vaginisme</span> - avec le code :&nbsp;</span>
    <span className="promotion-code semi-strong caps">&nbsp;MIADYS50</span>
  </Navbar>
);

export default IndexPage;
