import './styles.sass';

import React, { Component } from 'react';

import { Link } from 'gatsby';
import WhiteSeparator from 'images/icons/white-separator.svg';

class SubNavbar extends Component {
  render() {
    return (
      <>
        <div
          className="navbar-submenu"
        >
          <ul className="m-0 list-unstyled">
            <li className="d-inline-block">
              <Link
                to="/baisse-de-libido/"
                className="link-style-collapse-nav"
                activeClassName="active-page"
              >
                Baisse de libido
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                to="/douleur/"
                className="link-style-collapse-nav"
                activeClassName="active-page"
              >
                Douleurs
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                to="/plaisir/"
                className="link-style-collapse-nav"
                activeClassName="active-page"
              >
                Plaisir
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                to="/couple/"
                className="link-style-collapse-nav"
                activeClassName="active-page"
              >
                Couple
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                to="/epanouissement/"
                className="link-style-collapse-nav"
                activeClassName="active-page"
              >
                Épanouissement
              </Link>
            </li>
            <li className="d-inline-block">
              <WhiteSeparator />
            </li>
            <li className="d-inline-block">
              <Link
                to="/vaginisme/"
                className="link-style-collapse-nav"
                activeClassName="active-page"
              >
                Programme VAGINISME
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default SubNavbar;
