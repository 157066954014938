import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

import config from 'utils/siteConfig';
import { useLocation } from "@reach/router"

const StructuredWebPage = ({
  description, title
}) => {
  const {
    siteUrl,
    siteName,
    siteDescriptionMeta,
    legalName,
    facebook,
    twitter,
    youtubeChannelUrl,
    linkedInUrl,
    infogreffeUrl,
    legalAddress,
    legalPostcode,
    fullAddress,
    phoneNumber,
    legalCity
  } = config
  const pathname = useLocation().pathname

  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": `${siteUrl}${pathname}`,
    "inLanguage": "fr-FR",
    "isPartOf": {
      "@type": "WebSite",
      "url": siteUrl,
      "name": siteName,
      "genre": "Health",
      "description": siteDescriptionMeta,
      "publisher": {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": siteName,
        "description": siteDescriptionMeta,
        "slogan": siteDescriptionMeta,
        "legalName": legalName,
        "url": siteUrl,
        "telephone": phoneNumber,
        "logo": {
          "@type": "ImageObject",
          "url": `${siteUrl}${config.logo}`
        },
        "sameAs": [
          `https://www.facebook.com/${facebook}`,
          `https://www.twitter.com/${twitter}`,
          linkedInUrl,
          youtubeChannelUrl,
          infogreffeUrl,
          "https://www.google.com/search?q=mia.co"
        ],
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": phoneNumber,
            "email": "care@mia.co",
            "contactType": "customer support",
            "areaServed": "FR",
            "availableLanguage": "French"
          }
        ],
        "address": {
            "@type": "PostalAddress",
            "postalCode": legalPostcode,
            "addressLocality": legalCity,
            "streetAddress": legalAddress,
            "addressCountry": {
              "@type": "Country",
              "name": "France"
            }
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "bestRating": 5,
          "worstRating": 5,
          "ratingValue": 5,
          "ratingCount": 23,
          "itemReviewed": {
            "@type": "Organization",
            "name": siteName
          }
        },
      }
    },
    "description": description,
    "name": title,
    "about": {
      "@context": "https://schema.org",
      "@type": "Service",
      "serviceType": "Consultation avec des sexologues Francais",
      "areaServed": "France",
      "url": siteUrl
    },
    "offers": {
      "@type": "Offer",
      "availability": "http://schema.org/InStock",
      "businessFunction": "Consultation avec des sexologues Francais",
      "priceCurrency": "EUR",
      "price": "45 €",
      "description": siteDescriptionMeta,
      "name": "consultation en ligne avec un sexologue"
    }
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    </>
  );
};

StructuredWebPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default StructuredWebPage;
