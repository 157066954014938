import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import url from 'url';

import StructuredWebPage from './StructuredWebPage';
import ImageMeta from './ImageMeta';
import config from 'utils/siteConfig';

const CharlesMeta = ({
  data, canonical, title, description, image,
}) => {
  const shareImage = url.resolve(
    config.siteUrl,
    image || (data && data.feature_image) || config.shareImage,
  );

  description = description
        || data.meta_description
        || data.description
        || config.siteDescriptionMeta
        || config.siteDescriptionMeta;
  title = title
        || data.meta_title
        || data.name
        || data.title
        || config.siteTitleMeta;

  const lang = 'FR';
  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="description" content={description} />
        {canonical && <link rel="canonical" href={canonical} />}
        <meta property="og:site_name" content={config.siteName} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content={lang} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        <meta
          name="twitter:site"
          content={`https://twitter.com/${config.twitter.replace(
            /^@/,
            '',
          )}/`}
        />
        <meta name="twitter:creator" content={config.twitter} />
      </Helmet>
      <ImageMeta image={shareImage} />
      <StructuredWebPage
        title={title}
        description={description}
      />
    </>
  );
};

CharlesMeta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    feature_image: PropTypes.string,
    description: PropTypes.string,
    bio: PropTypes.string,
    profile_image: PropTypes.string,
  }),
  canonical: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.oneOf(['WebSite', 'Series']),
};
CharlesMeta.defaultProps = {
  data: {},
};

export default CharlesMeta;
